import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { inject } from "mobx-react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ProductsGrid from "../ProductsGrid";
import Stepper from "../BuyStepper";
import CombinedBuyForm from "../forms/CombinedBuyForm";
import { useMediaQuery } from "@material-ui/core";
import CloseButton from "../CloseButton";
import { useHistory } from "react-router-dom";
import usePortal from "../hooks/usePortal";
import SupportLink from "../SupportLink";

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 0,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginBottom: theme.spacing(1),
  },
  stripe: {
    border: '1px solid ' + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    fontSize: '1rem',
  },
  price: {
    color: theme.palette.text.primary,
    margin: 0,
  },
}));

const getPriceString = (recording, user) => {
  if (recording.price === 0)
    return "FREE";
  const memberPrice = `Member Price: $${recording.priceForMembers}`;
  const regPrice = `Regular Price: $${recording.price}`;
  if (!user)
    return memberPrice + "\r\n" + regPrice;
  return user.isMember ? memberPrice : regPrice;
}

const getRecordingProduct = (recording, user, canBuySingleRecording) => ((recording && canBuySingleRecording) ? {
  id: "recording",
  title: "Single Recording",
  description: getPriceString(recording, user),
  isAvailable: true,
  recording,
  pricingReasons: ["email"]
} : null);

const SignUpAndBuyDialog = ({ recording, user, handleClose, products, event, defaultSize = "sm", portal }) => {
  const [product, setProduct] = useState(null);
  const [stepIndex, setStepIndex] = useState(0);
  const showSignUpStep = useRef(false);
  const [size, setSize] = useState(defaultSize);

  const relatedProducts = useMemo(() => {
    if (!recording && !event)
      return products;
    if (recording?.type === "product")
      return products;
    return products.filter(x => (x.recordings || []).includes(recording?.id) || (x.events || []).includes(event?.id));
  }, [products, recording, event]);

  const steps = useMemo(() => {
    let result = [
      // { id: 0, label: "Sign Up" },
      { id: 1, label: "Select bundle", caption: product === null ? null : product.title },
      { id: 2, label: "Checkout" },
    ];
    if (!showSignUpStep)
      result.filter(x => x.id !== 0);
    if (relatedProducts.length === 0)
      result.filter(x => x.id !== 2);
    return result;
  }, [product, relatedProducts]);

  const currentStep = useMemo(() => steps[stepIndex], [steps, stepIndex]);

  const classes = useStyles();

  const handlePurchaseCompleted = data => {
    handleClose(data);
  }

  const handleStepIndexChange = index => {
    if (stepIndex === index)
      return;
    setStepIndex(index);
    setProduct(null);
    setSize(defaultSize);
  }

  useEffect(() => {
    // console.log("relatedProducts effects", [...relatedProducts.map(x => ({ ...x }))], recording);
    if (recording?.type === "product") {
      setProduct(relatedProducts.find(x => x.id === recording.id));
      return;
    }
    setProduct(p => {
      if (p)
        return p;
      if (relatedProducts.length === 0)
        return getRecordingProduct(recording, user, portal.canBuySingleRecording);
      if (recording?.price == null && relatedProducts.length === 1)
        return relatedProducts[0];
      return null;
    });
  }, [relatedProducts, recording, user]);

  useEffect(() => {
    if (user && currentStep.id === 0)
      setStepIndex(x => ++x);
  }, [user, currentStep]);

  useEffect(() => {
    if (product && currentStep.id !== 2)
      setStepIndex(x => ++x);
  }, [product, currentStep]);

  // console.log("render", [...relatedProducts.map(x => ({ ...x }))], steps, { ...user }, product);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const availableProducts = [getRecordingProduct(recording, user, portal.canBuySingleRecording), ...relatedProducts].filter(Boolean);
  const titleAction = product?.price ? "Buy " : "Register for ";
  const dialogTitle = product ? (product.id === "recording" ? titleAction + "Recording" : titleAction + product.title) : "Choose an Option";
  return (
    <Dialog open onClose={() => handleClose()} aria-labelledby="buy-dialog-title" fullWidth maxWidth={size} scroll="body" disableScrollLock fullScreen={fullScreen}>
      {fullScreen && <CloseButton onClick={() => handleClose(false)} />}
      <DialogTitle id="buy-dialog-title" className={classes.title}>Introduce Yourself</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {recording?.name || event?.name}
        </DialogContentText>
        {(steps.length > 1 && availableProducts.length > 1) && <Stepper step={stepIndex} steps={steps} onStepIndexChange={handleStepIndexChange} />}
        {currentStep.id === 1 &&
          <ProductsGrid
            onSelect={setProduct}
            products={availableProducts}
            md={relatedProducts.length > 1 ? 4 : 6} lg={relatedProducts.length > 1 ? 4 : 6}
          />
        }
        {(currentStep.id === 2 && product) &&
          <CombinedBuyForm product={product} onSuccess={handlePurchaseCompleted} setSize={setSize} />
        }
      </DialogContent>
      <DialogActions>
        <SupportLink />
        <div style={{ width: '100%' }} />
      </DialogActions>
    </Dialog>
  );
}

SignUpAndBuyDialog.propTypes = {
  store: PropTypes.object.isRequired
};

const InjectedSignUpAndBuyDialog = inject(({ store }, props) => ({
  products: store.products, store, user: store.currentUser, portal: store.portal
}))(SignUpAndBuyDialog);

export default InjectedSignUpAndBuyDialog;

export const SignUpAndBuyDialogRoute = () => {
  const history = useHistory();
  const { urlPrefix, uiSettings: { signInRedirect } } = usePortal();
  const handleClose = success => history.push(success ? urlPrefix + signInRedirect : "/");
  return <InjectedSignUpAndBuyDialog handleClose={handleClose} defaultSize="md" />;
}
